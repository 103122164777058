import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"30px"}},[_vm._v(" "+_vm._s(_vm.$t("company.companies"))+" ")]),_c('div',{staticClass:"mx-2"}),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.getCompanies}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.companies.length > 0 && !_vm.loading)?_c(VList,_vm._l((_vm.companies),function(company,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(company.name))])]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm._f("format")(company.createdAt))+" ")])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"elevation":"0","color":"primary","loading":_vm.toggling && _vm.toggled === company.companyId,"disabled":_vm.toggling && _vm.toggled === company.companyId},on:{"click":function($event){return _vm.toggle(company.companyId)}}},[_c('span',[_vm._v(_vm._s(_vm.isActive(company) ? "Deactivate" : "Activate"))])])],1)],1)}),1):_c('div',[(_vm.loading)?_c('div',[_vm._v(_vm._s(_vm.$t("company.getting")))]):_vm._e(),(_vm.companies.length === 0 && !_vm.loading)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("company.notfound")))])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }